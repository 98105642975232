@import '../../variables.scss';

.services-list-wrapper {
  width: 100%;

  .services-list {
    font-family: Poppins;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    &::-webkit-scrollbar { 
      width: 8px;
      height: 8px; 
    }

    &::-webkit-scrollbar-thumb {
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #dadce0;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      min-height: 40px;
  }

    .service-item {
      margin: 0 auto 40px;
    }
  }

  .services-list-title {
    font-weight: 600;
    font-size: 18px;
    color: $title-color;
    margin: 50px 0px 30px 0px;

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  @media only screen and (max-width: 1400px) {
    .services-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (max-width: 776px) {
    .services-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 600px) {
    .services-list {
      grid-template-columns: repeat(2, 1fr);

      .services-list-title {
        font-size: 16px;
        text-align: center;
        margin: 35px;
      }

      .service {
        width: 160px;
        height: 250px;
        margin: 0px auto !important;
      }
    }

  }

  @media only screen and (max-width: 400px) {
    .services-list {
      .service {
        width: 148px;
      }
    }
  }
  @media only screen and (max-width: 330px) {
    .services-list {
      grid-template-columns: repeat(1, 1fr);
      .service {
        width: 180px;
      }
    }
  }

}


