.jobTypes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .container{
    box-shadow: 0px 8px 16px rgba(59, 75, 82, 0.15), 0px 0px 4px rgba(59, 75, 82, 0.05);
    width:97%;
    height: 97px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    border-left: 8px solid transparent;

    &:hover{
      border-left: 8px solid #6AA8EE;
      transition: all 0.2s;
      p{
        color: #6AA8EE;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    p{
      margin-right: 5%;
      margin-left: 5%;
    }

    @media only screen and (max-width: 600px) {
      width: 98%;
      height: 76px;
      margin:unset;
      margin-bottom: 16px;
      p{
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        overflow: hidden;
        text-align: center;
        margin-right: unset;
        margin-left: unset;
      }
    }

  }