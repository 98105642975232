@import '../../../variables.scss';
@import "../../../assets/functions.scss";

.service-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  @media only screen and (max-width: 600px) {
    height: 277px;
  }
}

.service {
  width: 180px;
  height: 280px;
  font-family: Poppins;
  background: #FFFFFF;
  box-shadow: 0px 0px 9.6667px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: flex-start;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  .top{
    flex: 1;
  }
  &:hover{
    cursor: pointer;
    border: 4px solid #9FC7F5;
    border-radius: 13px;
  }

  .image {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
    height: 122px;

    .img-wrap {
      background: #3B4B52;
    }

    img {
      transition: all .2s ease-in-out;
      height: 122px;
    }

    .service-no-image-wrapper{
      background: #C4C4C4;
      transition: all .2s ease-in-out;
      height: 122px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      text-align: center;

      .service-no-image {
        width: 50px;
        height: 50px;
        background: #C4C4C4;
        text-align: center;
        margin-top: 36px;
      }
    }

    .price {
      @include price();
      top: 8px;
    }
  }


  .details-quantity {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;

    .service-name {
      font-weight: 600;
      line-height: 1.3em;
      font-size: 14px;
      word-break: break-word;
      margin-top: 10px;
      max-height: 2.6em;
      overflow: hidden;
      color: #3B4B52;
    }
    .description {
      font-size: 12px;
      margin-top: 5px;
      color: rgba(59, 76, 83, 0.7);
      word-break: break-word;
      max-height: 4.4em;
      overflow: hidden;
    }
  }

  .quantity-wrapper {
    opacity: 1;
    display: flex;
    bottom: 13px;
    right: auto;
    margin: 0;
  }


  .details-wrapper {
    padding-top: 8px;
  }

  .info{
    display: flex;
    align-items: center;
    padding: 4px;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    bottom: 8px;
    background: #FFFFFF;
    border-radius: 32px;
    box-sizing: border-box;

    .info-img {
      height: 12px;
      width: 12px;
      margin: auto !important;
    }
  }
  
  .info-wrapper{
    padding: 10px;
  }

  @media only screen and (max-width: 600px) {

    .details-wrapper {

      .details {
        width: auto;

        .description {
          display: none;
        }
      }

      .price-and-quantity {
        .quantity-wrapper {
          margin-top: 12px;

          .price {
            font-weight: 500;
            font-size: 13px;
          }

          .quantity-circle {
            color: #6aa8ef;
            border: solid 0px;
            box-shadow: none;
          }

          .quantity {
            margin: 0;
          }
        }
      }

    }

    .image {
      text-align: center;
    }

    // .info{
    //   bottom: 24px;
    // }
  }
  
}

.react-tooltip-lite {
    background-color: #FFFFFF;
    color: #3B4C5399;
    box-shadow: 0px 0px 20px 0px #00000026;
    border-radius: 8px;

    .hover-item-name{
      color: #3B4C53;
      font-size: 14px;
      word-break: break-word;
    }

    .hover-description {
      font-size: 13px;
      word-break: break-word;
    }
}

.react-tooltip-lite-arrow {
  color: #FFFFFF;
}

.addToCartButton{
  @include addToCartButton(124px);
  margin: 0 0 10px 0;
  padding: 0px;
  box-sizing: border-box;
  
  span{
    margin: auto;
  }

  &.disabled{
      @include disabledButton();
  }

  &:hover{
      @include hoverOnButton();
  }
}

.quantity-wrapper {
  opacity: 1;
  display: flex;
  background-color: #ffffffe0;
  bottom: 13px;
  right: 15px;
  &>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex: 1;
  }

  .quantity {
    color: #6AA8EE;
    border: 1px solid #F3F6F7;
    margin: 0px 8px;
  }
  .no-quantity{
    color: #BFC4C7;
  }

  .quantity-circle {
    height: 32px;
    background: #E8E8E8;
    cursor: pointer;
    color: #6AA8EE;
    font-weight: 600;
    font-size: 24px;
    &.remove{
      background-color: #E6F1FF;
    }
    &.add{
      background-color: #E6F1FF;
    }
    &.noQuantityBtn{
      background-color: #F3F6F7;
    }
  }
}

@media only screen and (max-width: 776px) {
  .service{
    margin: 0 auto; 
  }
}

@media only screen and (max-width: 375px) {
  .info-wrapper{
    .quantity-wrapper{
      margin-top: -5px;
    }
  }
}