.pane{
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 400px;
    right: -450px;
    transition: right 0.3s ease;
    background: #FFF;
    z-index: 99999;
    box-shadow: -4px 4px 16px rgba(59, 75, 82, 0.15), 0px 0px 4px rgba(59, 75, 82, 0.05);
    line-height: 1.3em;
    top: 0;
    margin-top: 78px;
    @media only screen and (max-width: 600px) {
        width: 100%;
        right: -100%;
    }
    &.open{
        right: 0;
    }
    .heading{
        display: flex;
        background-color: #F3F6F7;
        align-items: center;
        color: #3B4B52;
        font-weight: 600;
        text-align: center;
        padding: 20px 20px;
        line-height: 1.5em;
        .title{
            flex:1;
        }
        .close{
            cursor: pointer;
            font-size: 20px;
        }
    }
    .noItems{
        color: #566D76;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .invoice{
        padding: 20px;
        margin-bottom: 50px;
        overflow-y: scroll;
        .items{
            margin-bottom: 10px;
            
            .title{
                color: #3B4B52;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 20px;
            }
            .item{
                display: flex;
                margin-top: 20px;
                &>div{
                    flex:1;
                    display: flex;
                    font-size: 13px;
                }
                .name{
                    min-width: 200px;
                    flex: 1;
                    @media only screen and (max-width: 600px) {
                        min-width: 160px;
                    }
                    @media only screen and (max-width: 350px) {
                        min-width: 115px;
                    }
                }
                .qty{
                    opacity: 1;
                    display: flex;
                    position: relative;
                    top: -5px;
                    &>div{
                        height: 25px;
                        cursor: pointer;
                        color: #6AA8EE;
                        font-weight: 600;
                        
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        flex: 1 1;
                        min-width: 20px;
                    }
                    .qtyNumber{
                        color: #3B4C53;
                        margin: 0px 5px;
                    }
                    .remove{
                        background-color: #E6F1FF;
                        font-size: 20px;
                    }
                    .add{
                        background-color: #E6F1FF;
                        font-size: 20px;
                    }
                }
                .price{
                    justify-content: end;
                    @media only screen and (max-width: 600px) {
                        padding-left: 10px;
                    }
                }
                
            }
        }
        .totals {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 14px;
            color: #3B4B52;
            margin-bottom: 32px;
        }

        .subTotal{
            border-top: 4px solid #DFE2E3;
            padding-top: 9px;
        }

        .total {
            background: #F3F6F7;
            padding: 8px;
        }

        .paymentSection{
            .title{
                color: #3B4B52;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 10px;
            }
            .paymentItems{
                .paymentItem{
                    display: flex;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    &>div{
                        flex:1;
                        display: flex;
                        font-size: 12px;
                    }
                    .name{
                        min-width: 200px;
                        flex: 1;
                        @media only screen and (max-width: 600px) {
                            min-width: 160px;
                        }
                    }
                    .price{
                        justify-content: end;
                        @media only screen and (max-width: 600px) {
                            padding-left: 10px;
                        }
                    }
                }
            }

        }
    }
}