@import '../../variables.scss';

.success-page{

  .titles{
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px 0;
    
    &.no-thank-you{
      justify-content: flex-end;
    }

    .title{
      font-weight: 600;
      font-size: 18px;
      color: #3B4C53;
    }

    .booking-summary{
      color: #869195;
      font-weight: 600;
      font-size: 12px;
      margin-top: 58px;
    }
    .pending-approval-msg{
      width: 311px;
      margin-bottom: unset;
    }
  }


  .pending-approval-msg{
    width: unset;
    height: 34px;
    font-weight: 500;
    font-size: 12px;
    color: #3B4B52;
    background: #FEF8EC;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .summary{
    display: flex;
    flex-direction: column;
    position: relative;

    .summary-card{
      background: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(59, 75, 82, 0.1), 0px 0px 4px rgba(59, 75, 82, 0.05);
      border-radius: 8px;
      padding: 30px;
      display: flex;

      .header-border{
        width: 100%;
        height: 4px;
        background: #F7F8F8;
        border-radius: 24px;
      }

      .right {
        text-align: right;
      }

      .left {
        text-align: left;
      }
      
      .summary-titles{
        display: flex;
        padding-bottom: 5px;
        .left-section{
          display: flex;
        }
        .right-section{
          display: flex;
        }
        .services{
          margin-right: 5px;
        }
        &>div{
          min-width: 50px;
        }
        .title{
          position: relative;
          z-index: 1;
          color: #3B4C53;
          font-weight: 600;
          font-size: 14px;
          width: 150px;
        }
        .qty {
          width: 50px;
        }
      }


      .summary-left{
        flex: 1;
        position: relative;
        border: 1px solid #DFE2E3;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .services-wrapper{
          flex:1;
          .services{
            max-height: 200px;
            overflow: auto;
            &.with-tax{
              border-bottom: 4px solid #DFE2E3;
            }
          }

          .service-row{
            display: flex;
            font-size: 14px;
            padding: 15px 0px;
            color: #566D76;

            .jobType {
              margin-right: 5px;
            }

            &>div{
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
  
              &:last-child{
                color: #3B4B52;
                font-weight: 600;
              }
            }
            .qty {
              width: 50px;
            }
            
            
          }

          .sub-total-separator{
            background: rgba(145, 155, 158, 0.8);
            height: 1.5px;
            width: 93%;
          }

          .sub-total-row{
            display: flex;
            justify-content: space-between;
            color: #3B4B52;
            font-weight: 500;
            font-size: 14px;
            margin-top: 12px;

            .sub-total-data{
              display: flex;
              justify-content: flex-end;
              margin-right: 58px;
            }

            .total-tax{
              width: 30px;
              text-align: center;
              margin-left: 45px;
            }

            .total-amount{

            }
            &:last-child{
              margin-bottom: 24px;
            }
          }
        }

        .summary-total{
          display: flex;
          justify-content: space-between;
          height: 32px;
          background: #F7F8F8;
          line-height: 32px;
          padding: 0px 5px;
          font-weight: 600;
          .items-in-total{
            color: #3B4B52;
            font-weight: 600;
            font-size: 14px;
            margin-right: 6px;
          }

          .total-amount{
            font-size: 16px;
            color: #3B4C53;
          }
        }

        .no-services-mode{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 38px auto;

          img{
            width: 48px;
            height: 42px;
          }

          .thank-you{
            font-weight: 600;
            font-size: 18px;
            color: #3B4C53;
            margin-top: 16px;
          }

          .pending-approval{
            width: 311px;
            height: 34px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.4px;
            margin-top: 10px;
            background: #FEF8EC;
            border-radius: 4px;
          }
        }

      }

      .summary-right{
        display: flex;
        flex-direction: column;
        .summary-titles{
          padding-left: 32px;

          &.date-time{
            padding:0px;
            justify-content: center;
            margin-bottom: 5px;

            .title{
              text-align: center;
            }
          }
        }

        .date-and-time{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 6px;
          padding: 20px;
          background-color: #F3F6F7;
          margin-left: 20px;
          border-radius: 6px;
          color: #3B4B52;
          .date{
            border-bottom: 1px solid #DFE2E3;
            padding-bottom: 10px;
          }
          .time{
            padding-top: 10px;
            font-weight: 600;
            white-space: nowrap;
          }
        }

        .location-wrapper{
          display: flex;
          flex-direction: column;
          text-align: left;
          padding-left: 20px;
          margin-top: 20px;

          .location-title{
            color: #3B4B52;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 4px;
          }

          .address{
            color: #566D76;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 13px;
          }

          .map-wrapper{
            margin-top: 10px;
            border-radius: 6px;
            overflow: hidden;
          }

          #map{
            width: 190px;
            height: 120px;
          }
        }
      }

    }
  }


  .contact-wrapper{
    background-color: #F3F6F7;
    display: flex;
    padding: 15px;
    margin: 20px 0px;
    border-radius: 6px;
    .left-side{
      flex: 1;
      color: #3B4B52;
      font-weight: 600;
      font-size: 14px;
    }
    .right-side{
      display: flex;
      align-items: center;
      .phone-wrapper{
        display: flex;
        .dot{
          width: 3px;
          color: #6AA8EE;
          margin-left: 8px;
          margin-right: 10px;
        }
        &>a{
          display: flex;
          align-items: center;
          margin-left: 8px;
          color: #6AA8EE;
          text-decoration: none;
          font-weight: 600;
          font-size: 13px;
          &>img{
            margin-right: 4px;
          }
        }
      }
      &>div{
        flex: 1;
        color: #3B4B52;
        font-weight: 600;
        font-size: 14px;
      }
      &>a{
        display: flex;
        align-items: center;
        color: #6AA8EE;
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;
        &>img{
          margin-right: 4px;
        }
      }
    }
  }

  .no-padding{
    padding: unset !important;;
  }

  @media only screen and (max-width: 790px) {
    .titles{
      margin:10px 0px;
    }

    .right-side {
      display: block !important;
    }

    .phone {
       margin-left: 0 !important;
    }

    .email {
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .hide {
      display: none !important;
    }

  }
  
  @media only screen and (max-width: 600px) {
    .summary{
        .titles{
          flex-direction: column;
          text-align: center;
          .pending-approval-msg{
            text-align: center;
          }
          .title{
            text-align: left;
            margin-top: 25px;
          }
        }
        .summary-card{
          flex-direction: column;
          padding: 10px;

          .summary-right{ 
            .date-and-time{
              margin: 10px 0px;
            }
            .location-wrapper{
              padding: 0px;
            }
          }
      }
    }
    .contact-wrapper{
      flex-direction: column;
      &>div{
        margin-bottom: 5px;
      }
      &>a{
        margin-left: unset;
      }
    }

    .dot {
      display: none;
    }
    
  }

}