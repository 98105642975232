.container{
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #FFF;
    box-shadow: 0px 4px 12px rgba(59, 75, 82, 0.1), 0px 0px 4px rgba(59, 75, 82, 0.05);
    border-radius: 12px;
    min-height: 394px;
    .topBar{
        display: flex;
        margin-bottom: 25px;
        &>div:nth-child(1){
            flex: 1;
        }
        .dateTitle{
            font-weight: 600;
        }
        .selected {
            color: #566D76;
        }
        .notSelected {
            color: #9EA6AA;
        }
        .dateNav{
            display: flex;
            &>div{
                background-color: #E6F1FF;
                border-radius: 4px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
                color: #6AA8EE;
                font-weight: 600;
                font-size: 14px;
                &.disabled{
                    background-color: #F3F6F7;
                    cursor: not-allowed;
                }
                .iconDisabled{
                    color: #9EA6AA;
                }
            }
        }
    }
    .days{
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid #DFE2E3;
        justify-content: space-around;
    }
    .day{
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        max-width: 80px;
        height: 80px;
        transition: background-color .3s ease;
        border: 1px solid transparent;
        &:hover{
            border-radius: 4px;
            border: 1px solid #6AA8EE;
        }
        &.today{
            opacity: 1 !important;
            .dayDate{
                color: #6AA8EE;
            }
        }
        &.disabled{
            opacity: 0.3 !important;
            cursor: not-allowed;
        }
        &.selected{
            background-color: #6AA8EE;
            border-radius: 4px;
            .dayName{
                color: #FFF;
            }
            .dayDate{
                color: #FFF;
                font-weight: 600;
            }
            &:hover{
                background-color: #639CDD;
            }
            &>.today{
                background-color: #FFFFFF;
            }
        }
        .today{
            height: 3px;
            background-color: #6AA8EE;
            border-radius: 3px;
            width: 20px;
            position: relative;
            top: 3px;
        }
        .dayName{
            font-size: 16px;
            color: #9EA6AA;
            font-weight: 600;
        }
        .dayDate{
            color: #3B4B52;
            font-size: 18px;
        }
    }
    .timeSelect{
        .title{
            color: #3B4B52;
            font-weight: 400;
            margin: 24px 0px 12px 0px;
            font-size: 16px;
        }
        .noSlots{
            background-color: #F3F6F7;
            color: #768287;
            font-size: 14px;
            padding: 40px;
            text-align: center;
        }
        .slots{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            .slot{
                flex:1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                padding: 8px 12px;
                margin: 5px 8px 7px 0px;
                color: #3B4B52;
                font-size: 14px;
                cursor: pointer;
                transition: background-color .3s ease;
                border: 1px solid #6AA8EE;
                white-space: nowrap;
                &:hover{
                    background-color: #E5F1FF;
                }
                &.selected{
                    background-color: #6AA8EE;
                    color: #FFFFFF;
                    font-weight: 600;
                }
            }
        }
    }
    .chooseDateContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 14%;
        margin: 0 auto;
        gap: 32px;
        background: #FFFFFF;
        .title{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #768287;
        }
    }
    
}

@media screen and (max-width: 330px) {
    .container {
        .timeSelect {
            .slots{
                .slot{
                    padding: 8px 15px;
                    white-space: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container{
        padding: 10px;
        min-height: 400px;
        .day{
            height: 65px;
            .dayName{
                font-size: 12px;
            }
            border: unset;
            &:hover{
                border: unset;
            }
        }
        .timeSelect{
            .slots{
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .chooseDateContainer{
        padding: 8% !important;
        .title{
            margin-top: 20% !important;
        }
    }
    
}