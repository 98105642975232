.container{
    display: flex;
    margin-top: 30px;
    align-items: center;
    .connector{
        flex: 1;
        height: 2px;
        background-color: #d1d5db;
    }
    .dot{
        background-color: #DFE2E3;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        margin: 0px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;
        &:before{
            content: '';
            width: auto;
            background-color: #DFE2E3;
            height: 2px
        }
        i{
            display: none;
        }
    }
    .number{
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .step{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label{
            position: absolute;
            margin-top: 60px;
            color: #566D76;
            font-size: 13px;
            font-weight: 500;
        }
        &.done{
            .number{
                .dot{
                    background-color: #6AA8EE;
                    i{
                        display: flex;
                        color: #FFF;
                        font-size: 21px;
                    }
                }
            }
        }
        &:first-child{
            .connector:first-child{
                opacity: 0;
            }
        }
        &:last-child{
            .connector:last-child{
                opacity: 0;
            }
        }
        &.active{
            .dot{
                width: 16px;
                height: 16px;
                background-color: #6AA8EE;
                filter: drop-shadow(0px 2px 8px rgba(106, 168, 238, 0.4));
                border: 3px solid #fff;
                box-shadow: 0 0 0 2px #6AA8EE;
            }
            .label{
                color: #6AA8EE;
            }
        }
    }
}