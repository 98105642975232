.button{
    color: #fff;
    background: #50d58c;
    font-weight: 600;
    font-size: 13px;
    padding: 5px 10px;
    line-height: 28px;
    border-radius: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    margin-left: auto;
    align-items: center;
    i{
        margin:0px 8px;
    }
    span{
        flex:1;
        text-align: center;
    }
    .loading{
        margin-right: 5px;
    }
    img{
        width: 24px;
        margin-right: 4px;
    }
}