
.step-title{
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-left: 8px;

  .title{
    font-weight: 400;
    font-size: 16px;
    color: #3B4B52;

    span{
      font-weight: normal;
      font-size: 14px;
      margin-left: 8px;
    }
  }


  @media only screen and (max-width: 600px) {

    .title{
      font-size: 16px;

      span{
        font-size: 12px;
      }

      &.margin-top {
        margin-top: 8px;
      }
    }

    .subtitle{
      font-size: 12px;
    }

  }
}