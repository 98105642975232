.header{
  background: #FFFFFF;
  width: 100%;
  line-height: 66px;
  font-family: Poppins;
  min-height: 78px;

  .header-inner{
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
    max-width: 790px;

    &.no-logo{

      .left-side{
        .content{
          margin: 0;
          .account-name,.account-slogan{
            margin-left: 0px;
          }
        }
      }
      .right-side{
        margin-top: 13px;
      }
    }

    .left-side{
      display: flex;
      margin-top: 13px;
      margin-left: 13px;

      .account-logo{
        width: 82px;
        margin-right: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        border: 1px solid #DFE2E3;
      }

      .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 13px;

        .account-name{
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #3B4B52;
        }

        .account-slogan{
          color: #768287;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
        .account-adress {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.4px;
        }
      }
    }

    .right-side{
      font-size: 12px;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
    }
  }




  @media only screen and (max-width: 600px) {
    width: 100%;
    line-height: normal;



    .header-inner{
      padding: 0px 10px;
      &.no-logo{
        padding-left: 0px;

        .left-side{

          .content{
            margin-left: 9px;

            .account-name,.account-slogan{
              margin-left: 0px;
            }
          }
        }
      }

      .left-side{

        .account-logo{
          width: 72px;
          height: 42px;
          margin: 0 0 12px 0;
        }

        .content{
          margin-left: 8px;
          .account-name{
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin: 0;
            letter-spacing: 0.4px;
        }
          .account-slogan{
            font-size: 12px;
            margin: 0;
            font-weight: 500;
            line-height: 21px;
            width: 95%;
          }
        }
      }

      .right-side{
        .powered-by{
          display: none;
        }

        .workiz-logo{
          display: none;
        }

        .shopping-cart-wrapper{
          .amount {
            background: #50D58C;
            border-radius: 33px;
            height: 18px;
            width: 40px;
            color: #FFFFFF;
            position: absolute;
            font-size: 13px;
            line-height: 17px;
            text-align: center;
            top: 13px;
            right: 38px;
            font-weight: 500;
          }
        }
      }
    }
  }
}