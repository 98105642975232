@import '../../variables.scss';

.tooltip-content .react-tooltip-lite {
  width: 177px !important;
}

.cvv-tooltip-content {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;

  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 5px;
    }

    div {
      font-size: 9px;
      font-weight: 600;
    }

    .img {
      width: 25px;
      margin-bottom: 4px;
    }
  }

  .line {
    border: 1px dashed #D8DBDD;
    margin: 0px 13px;
  }

  .green {
    color: #50D58C;
  }
}

.payment {
  display: flex;
  flex-direction: column;

  .card {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(59, 75, 82, 0.1), 0px 0px 4px rgba(59, 75, 82, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    .expiration-and-cvv{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
    }

    .sajInput {
      width: 343px;
      height: 48px;
      border: 1px solid rgba(158, 166, 170, 0.8);
      border-radius: 3px;
      padding: 0px 10px;
      transition: border 0.3s;
      color: #3B4C53;
      font-size: 16px;

      &.half-size{
        width: 140px;
      }
    }

    .fLabel{
      position: relative;
    }
    .fLabel label.label {
      line-height: 20px;
      pointer-events: none;
      position: absolute;
      top: 0.95rem;
      font-size: 14px;
      font-weight: 400;
      left: 0.65rem;
      color: #8C8C8C;
      transition: all 0.2s ease;
    }
    
    .holder-name-wrapper{
      height: 74px;
      width: 366px;
    }

    .StripeElement--focus + label,
    .StripeElement:hover + label,
    .StripeElement:not(.StripeElement--empty) + label{
      font-size: 12px;
      top: -10px;
      background: #FFFFFF;
      padding: 0px 5px;
    }

    .StripeElement--focus{
      border:1px solid #ffd400;
    }

    .StripeElement{
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 3px;
    }

    .top {
      display: flex;
      z-index: 99;
      position: relative;
    }

    .background-image {
      position: absolute;
      right: 0px;
      height: 333px;
    }

    .stripe-error{
      color: #FF6F64;
      font-size: 12px;
      height: 20px;
    }

    .fields-wrapper {
      display: flex;
      flex-direction: column;

      .input-field {
        margin-bottom: 13px;
        width: 100%;

        &.expiration{
          width: 100%;
          margin-right: 16px;
        }


        p{
          margin: 2px;
          font-family: Poppins;
        }
      }

      .MuiFormHelperText-root-354 {
        color: $error;
        margin-top: 2px;
      }

      .select-field {
        width: 166px;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .credit-card-img{
      // margin: 44px 0px 0px 47px;
      margin:0 auto;
    }

    .card-wrapper {
      margin: 59px 67px 0px 35px;
      display: flex;
      flex-direction: column;

      .preview {
        font-weight: 600;
        font-size: 16px;
        color: #9EA6AA;
        margin-bottom: 38px;
        text-align: center;
      }

      .rccs__card {
        width: 240px;
        height: 140px;

        .rccs__number {
          font-size: 14px;
        }

        .rccs__cvc {
          left: 64%;
          top: 40%;
        }

        .rccs__name {
          font-size: 11px;
        }

        .rccs__expiry__value {
          font-size: 11px;
        }

        .rccs__issuer {
          &.rccs__card--visaelectron {
            background-size: 61%;
          }
        }

        .rccs__chip {
          display: none;
        }
      }
    }
    .disclaimer-wrapper{
      display: flex;
      align-items: center;
      gap: 5px;
      &>img{
        width:12px;
        height:12px;
      }
      &>.disclaimer{
        width: 313px;
        height: 19px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #768287;
      }
    }
  }

  .expiration-wrapper {
    display: flex;
  }

  .credit-card-details {

    .cvv {
      width: 20%;
      margin-right: 2%;
    }

    .credit-card {
      width: 78%;
    }
  }

  .question-mark {
    width: 16px;
    margin-bottom: 13px;
  }

  @media only screen and (max-width: 1300px) {
    width: 100%;
    height: 100%;

    .card-wrapper {
      padding-bottom: 30px;
    }

    .card {
      padding-top: 20px;

      .holder-name-wrapper{
        height: 74px;
        width: 100%;
      }

      .wepay-fields-wrapper{
        width: 92%;
        margin: 0px 4%;
      }


      &.stripe{
        padding: 30px;
        flex-direction: column;

        .total-deposit{
          margin-top: 48px;
        }

        .holder-name-wrapper{
          margin-bottom: 3px;
        }

        .fields-wrapper{
          padding:0px;
          width: 100%;
        }

        .credit-card-img{
          width: 240px;
          height: 140px;
          margin:0;
        }

        .sajInput{
          width: 91.5%;
          padding: 0px 4%;

          &.half-size{
            width: 76%;
            padding-left: 8%;
          }
        }

        .expiration-and-cvv{
          .fLabel{
            width: 100%;
            justify-content: start;

            &:nth-child(2){
              justify-content: end;
            }
          }
        }

        .top{
          padding: 0px 4%;
          align-items: center;
        }
      }



      .card-wrapper {
        margin: 33px 20px 0px;
      }

      .top{
        flex-direction: column-reverse;
      }

      .background-image {
        position: absolute;
        right: 0px;
        top: -23px;
      }

      .fields-wrapper{
        width: 100%;
        padding: 0px;

        .input-field {
          margin-bottom: 20px;

          &.expiration{
            width: 250px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 855px) {
    .card.stripe{
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    height: inherit;
    padding: 0px 0px 20px 0px;
    margin: 0px 4%;
    width: 92%;
    .background-image{
      display: none;
    }
    .top{
      flex-direction:column !important;
      .credit-card-img{
        display: none;
      }
    }
    .card-wrapper {
      padding-bottom: 0;
    }

    .card {
      width: 100%;

      &.stripe {
        padding: 30px 0px;
        .credit-card-img{
          width: 160px;
          height: 90px;
        }
      }
    }
    .disclaimer-wrapper{
      margin: 0 auto  18px 15px;
      &>.disclaimer{
        width: 199px !important;
        height: 38px;
        word-break: break-word;
      }
    }
  }
}