@import '../../variables.scss';

.job-types{
    .job-types-options{
        margin-top: 22px;
        cursor: pointer;
    }
}



