@import '../../variables.scss';
@import "../../assets/functions.scss";

.title-tooltip-content {
  .react-tooltip-lite {
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.4px;
    border-radius: 4px;
  }
}

.detailsModal{
  width: auto;
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;

  .description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: rgba(59, 76, 83, 0.7);
    overflow: auto;
    max-height: 195px;
    overflow-x: hidden;
    white-space: pre-wrap;
  }

  .title {
    display: inline-block;
    left: 500px;
    top: 166px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #3B4B52;
    overflow: hidden;
    width: 100%;
    max-width: 440px;
    margin-bottom: 0px;
  }

  .price{
    color: #566D76;
    font-weight: 600;
    &.withQuantity{
      color: #6AA8EE;
    }
  }

  .image{
    height: 220px;
    width: 440px;
    float: left;
    margin-bottom: 26px;
    display: flex;
    justify-content: center;
    background: #3B4B52;
    border-radius: 8px;
    
    .img{
      height: 100%;
      border-radius: 8px;
      max-width: 100%;
    }
  }

  .addToCartButton{
    margin: 15px 0px 25px 0px;
    span{
      margin: 0 auto;
      line-height: 35px;
    }
  }

  .wrapper-details{
    display: inline-block;
    width: 440px;

    .info-wrap {
      width: 120px;
      margin: 20px 0;
    }

  }

@media only screen and (max-width: 600px) {
    width: auto;
    height: auto;
    margin: auto;
    padding: 0;
    display: block;
    

    .wrapper-details{
      min-width: 230px;
      width: 100%;
      max-width: 312px;

      .description{
        max-height: 374px;
        padding-right: 5px;

        &.noImg{
          max-height: 548px;
        }
      }
    }

  .title{
    max-width: 312px;
  }

  .image{
    float: none;
    margin: auto;
    width: 315px;
    height: 175px;
    margin-bottom: 15px;
  }

  .addToCartButton{
    margin-bottom: 21px;
    width: 124px;
    height: 38px;
  }

 }

 @media only screen and (max-width: 400px) {

  .wrapper-details{
    min-width: auto;
    width: 100%;
    max-width: 288px;
  }

  .title{
    max-width: 288px;
  }

  .image{
    width: 100%;
  }
 }

}

h2{
  text-align: right;
}

.closeIcon{
  cursor: pointer;
}

#alert-dialog-title{
  padding: 10px 13px 0 24px;
}
.MuiDialog-paper-5 {
  overflow: unset !important;
}
#dialog-content {
  overflow: unset;
}

@media only screen and (max-width: 600px) {
  #dialog > div{
  align-items: flex-start;
  padding-top: 17px;
  }
}


