.cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #DFE2E3;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    top: 15px;
    
    &:hover{
      background: #F3F6F7;
    }
    &.withItems{
      border: 1px solid #3ACF7D;
    }
    img{
      width: 24px;
    }
    .counter{
      background-color: #3ACF7D;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      position: absolute;
      color: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: -8px;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }