.container{
    position: fixed;
    width: calc(100% - 40px);
    padding: 20px;
    bottom: 0;
    box-shadow: 0px -2px 4px rgba(59, 75, 82, 0.05), 0px 0px 4px rgba(59, 75, 82, 0.1);
    display: flex;
    justify-content: center;
    background: rgb(252,252,252, 0.8);
    z-index: 999;
    @media only screen and (max-width: 790px) {
        min-width: unset;
    }
    .poweredBy{
        width: 33.33%;
        color: #566D76;
        @media only screen and (max-width: 600px) {
            display: none;
        }
        .poweredByLink{
            margin-bottom: 4px;
            font-family: Poppins;
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #3B4B52;
            
        }
        a {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.wrapperLeftBtn {
    width: 33.33%;
}
.wrapperRightBtn{
    width: 33.33%;
    display: flex;
    justify-content: flex-end;
}

.button{
    margin-left: 0 !important;
    width: fit-content;
    height: fit-content;
    &.secondary{
        margin-left: unset;
        background: #FFF;
        border: 1px solid #3B4B52;
        color: #3B4B52;
        min-height: 30px;
    }
    &.back{
        @media only screen and (max-width: 600px) {
            min-width: unset;
            span{
                display: none;
            }
        }
    }
    &.disabled{
        background-color: #BFC4C7;
        cursor: not-allowed;
    }
    &.disabledBack{
        cursor: not-allowed;
    }
    &.center{
        margin: 0 auto;
    }
    &.right{
        margin-left: auto;
    }
    &.paymentBtn{
        span{
            flex:unset !important;
        }
    }
}

.buttons{
    min-width: 790px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 790px) {
        min-width: unset;
        flex: 1;
    }
}